import {  Divider, Table, Badge, Title, Text, Card, Button, SimpleGrid, Container} from '@mantine/core';
import { useTranslation } from "react-i18next";
import { JSONPath } from "jsonpath-plus";
import people from "./sek_people.json"
import { useStyles } from '../app/styles';


/**
 * The About us page shows what the SEK is and who is behind her
 */
export const Profile = () => {
    const { t, i18n } = useTranslation();
    const { classes } = useStyles();

    const queryParams = new URLSearchParams(window.location.search);
    const nethzSearched = queryParams.get('nethz');
    const userInfo = JSONPath({path: "$[*][?(@.nethz=='"+nethzSearched+"')]", json: people})[0];

    const techStack = [];
    for (let tech of userInfo.tech_stack){
        techStack.push(<Badge className={classes.badge}>{tech}</Badge>);
    }
    const projects = [];
    for (let project of userInfo.projects){
        projects.push(<Card withBorder={true} style={{height: '100%', verticalAlign: 'middle', maxWidth: '312px', margin: 'auto', width: '100%'}}>
                            <Title order={5} align='center'>
                            { project.title ? (project.title):( i18n.language==='en' ? (project.title_en):(project.title_de))}
                            </Title>

                            <Text align='center'>
                            <Button component='a' target='_blank' style={{ margin: 10 }} href={project.repo}>GitLab</Button>
                            {project.url !== "" &&
                              <Button component='a' target='_blank' style={{ margin: 10 }} href={project.url}>{t("Profile.website")}</Button>}
                            </Text>
                        </Card>);
    }

    return (
        <>
            <SimpleGrid cols={2}
                  breakpoints={[
                    { maxWidth: 646, cols: 1, spacing: 'sm' },
                  ]}>
                <Container style={{width: '100%', maxHeight: '269pt', aspectRatio: '1/1'}}>
                    <img src={userInfo['image_path']} style={{height: '100%', width: '100%', objectFit: 'scale-down'}}/>
                </Container>
                <Container>
                    <Table>
                        <tbody>
                            <tr><td>{t("Profile.name")}</td><td>{userInfo['name']}</td></tr>
                            <tr><td>{t("Profile.function")}</td><td>{ i18n.language==='en' ? (userInfo['position_en']):(userInfo['position_de'])}</td></tr>
                            {userInfo['position_en'] !== 'Member' ? <tr><td>{t("Profile.task")}</td><td>{ i18n.language==='en' ? (userInfo['task_en']):(userInfo['task_de'])}</td></tr>: <></>}
                            <tr><td>{t("Profile.mail")}</td><td>{userInfo['mail']}</td></tr>
                            <tr><td>{t("Profile.tech")}</td><td>{techStack}</td></tr>
                        </tbody>
                    </Table>
                </Container>
            </SimpleGrid>
            <Divider my="sm" />
            <Title order={4}>{t("Profile.projects")}</Title>
            <SimpleGrid cols={3}
                  breakpoints={[
                    { maxWidth: 900, cols: 2, spacing: 'sm' },
                    { maxWidth: 601, cols: 1, spacing: 'sm' },
                  ]}>
                {projects}
            </SimpleGrid>
        </>
    );
};


