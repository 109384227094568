import { Badge, Button, Card, SimpleGrid, Text, Title } from '@mantine/core';
import { useTranslation } from "react-i18next";
import { PlusCircleIcon } from "vseth-canine-ui"
import { useStyles } from '../app/styles';

import projectsFile from "./sek_projects.json"

/**
 * 
 */

export const Projects = () => {
    const { t, i18n } = useTranslation();
    const { classes } = useStyles();

    const projectCardList = [];
    for (let project of projectsFile.projects){
        const techList = [];
        for (let tech of project.tech_stack){
            techList.push(<Badge className={classes.badge}>{tech}</Badge>);
        }
        projectCardList.push(
        <Card withBorder={true} style={{height: '100%', verticalAlign: 'middle', maxWidth: '312px', margin: 'auto', width: '100%'}}>
            <Title order={5} align='center'>
            { i18n.language==='en' ? (project.title_en):(project.title_de)}  
            </Title>
            <Text align='center'>
            { i18n.language==='en' ? (project.description_en):(project.description_de)}
            </Text>
            <Text align='center'>
            {techList}
            </Text>
            <Text align='center'>
            {project.repo !== "" &&
            <Button component='a' target='_blank' style={{ margin: 10 }} href={project.repo}>Repo</Button>
            }
            {project.wiki !== "" &&
            <Button component='a' target='_blank' style={{ margin: 10 }} href={project.wiki}>Wiki</Button>
            }
            </Text>
        </Card>);
    }

    projectCardList.push(
    <Card p="xl" component="a" href="mailto:sek@vseth.ethz.ch" className={classes.plusProjectCard}>
        <div style={{height: '100%', width: '100%', display: 'table'}}> 
            <div style={{display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', height: '100%'}}>
                <Title order={5} align='center' style={{fontSize: 30, marginBottom: '10px'}}>
                    {i18n.language==='en' ? "Your idea":"Deine Idee"}
                </Title>
                <Text align='center' style={{fontSize: 50}}>
                    <PlusCircleIcon color='#fff'/>
                </Text>
            </div>
        </div>
    </Card>
    )

    return (
        <>
            <Title>{t("Project.title")}</Title>
            <Text className={classes.text}>{t("Project.text")} <a href='mailto:sek@vseth.ethz.ch'>sek@vseth.ethz.ch</a></Text>
            <SimpleGrid cols={3}
                  breakpoints={[
                    { maxWidth: 900, cols: 2, spacing: 'sm' },
                    { maxWidth: 601, cols: 1, spacing: 'sm' },
                  ]}>
                {projectCardList}
            </SimpleGrid>
        </>
    );
};
