import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    text: {
        textAlign: 'justify'
    },
    badge: {
        margin: 2
    },
    bigCard: {
        backgroundColor: theme.colors.vsethMain[7],
        color: '#fff'
    },
    plusProjectCard: {
        backgroundColor: theme.colors.vsethMain[7],
        maxWidth: '312px',
        margin: 'auto',
        width: '100%',
        height: '100%',
        color: '#fff',
    }
}));